import { Stack } from "@fluentui/react";
import React, { useEffect, useState } from "react";
import { MyMessageProps } from "../AppComponents/MyChat";
import { ChatMessageIncomingTextComponentStyle, ChatMessageOutgoingTextComponentStyle, ChatMessageTextComponentLongMessageStyle, Theme } from "../Utilities/MyChatStyles";
import DOMPurify from "dompurify";
import { marked } from "marked";
import FeedbackActions from "./FeedbackActions";
import { isSingleLongWord } from "../Utilities/Utility";

export interface ChatMessageComponentProps {
    singleMessageProps: MyMessageProps;
    isMobile: boolean;
}


const ChatMessageComponent: React.FC<ChatMessageComponentProps> = ({ singleMessageProps, isMobile }) => {

    //await createMarkup in useeffect
    const [rawHtml, setRawHtml] = useState('');
    const isSingleLongWordCheck = isSingleLongWord(singleMessageProps.message);

    // Remove seconds from timestamp
    const formattedTimestamp = singleMessageProps.timeStamp.replace(/:\d{2}$/, '');

    // Dependency array ensures this runs only when the message changes
    useEffect(() => {
        async function convertMarkdown() {
            // Convert Markdown to HTML and sanitize it
            const rawHtml = await marked(singleMessageProps.message); // Assuming 'marked' returns a promise
            const cleanHtml = DOMPurify.sanitize(rawHtml);
            setRawHtml(cleanHtml);
        }

        convertMarkdown();
    }, [singleMessageProps.message]); // Dependency array ensures this runs only when the message changes


    return (
        <>
            {singleMessageProps.direction === "outgoing" && <>
                <Stack.Item styles={{ root: { marginTop: "-14px" } }}>
                    <div
                        style={isSingleLongWordCheck ? ChatMessageTextComponentLongMessageStyle : ChatMessageOutgoingTextComponentStyle}
                        dangerouslySetInnerHTML={{ __html: rawHtml }}
                    />
                </Stack.Item>
                <Stack verticalAlign="center" styles={{ root: { marginTop: "-10px" } }}>
                    <Stack.Item align="end" style={{color: `${Theme.palette.themeSecondary}`, fontSize: "0.8rem", fontFamily: Theme.fonts.xLarge.fontFamily, fontWeight: 600, textAlign: "right", marginTop: "0px" }}>{formattedTimestamp}</Stack.Item>
                </Stack>
            </>}
            {singleMessageProps.direction === "incoming" && <>
                <Stack horizontalAlign="end" tokens={{ childrenGap: 4 }} styles={{ root: { marginTop: 12 } }}>
                    <Stack.Item styles={{ root: { marginBottom: "-20px", marginTop: "-14px" } }}>
                        <div
                            style={isSingleLongWordCheck ? ChatMessageTextComponentLongMessageStyle : ChatMessageIncomingTextComponentStyle}
                            dangerouslySetInnerHTML={{ __html: rawHtml }}
                        />
                    </Stack.Item>
                    <Stack horizontal horizontalAlign="end" tokens={{ childrenGap: 10 }} styles={{ root: { minWidth: 60, paddingTop: 10 } }}>
                        {singleMessageProps.direction === "incoming" && (
                            <Stack.Item align="center" styles={{ root: { marginBottom: "-10px" } }}><FeedbackActions targetMessage={singleMessageProps} /></Stack.Item>
                        )}
                        <Stack.Item align="center" style={{color: `${Theme.palette.themeSecondary}`, fontSize: "0.8rem", fontFamily: Theme.fonts.small.fontFamily, fontWeight: 600, textAlign: "right", paddingLeft: 6,marginTop: "-12px" }}>{formattedTimestamp}</Stack.Item>
                    </Stack>
                </Stack>
            </>}
        </>
    );
}

export default ChatMessageComponent;