//Reference for language codes: https://stackoverflow.com/questions/5580876/navigator-language-list-of-all-languages

export interface IGreetings {
    [language: string]: {
        greetings: ITimeSpecificMessages;
    };
}

export interface IImpulses {
    [language: string]: {
        impulses: string[];
    };
}

export interface ITimeSpecificMessages {
    morning: string[];
    afternoon: string[];
    evening: string[];
}


// Greetings for the user
export const greetings: IGreetings = {
    en: {
        greetings: {
            morning: [
                "Hey dear [User], nice to have you here! 🌸 How are you experiencing your relationship right now and what concerns you the most about it? 💕 I look forward to your reply! 😊"
                // "Good morning [User], how did you wake up feeling today? Looking forward to your thoughts. All the best 🌟",
                // "Hello [User], what's on your agenda today? Excited to hear about it. Have a lovely day 💫",
                // "Morning [User]! Any particular goal driving you today? Can't wait to hear about it. All the best 💖",
                // "Hey [User], how's your morning shaping up? Hope it's starting off well. Sending love 💜",
                // "Good morning [User]! What's the first thing you're tackling today? Eager to know. Best wishes 💙",
                // "Hello [User], what's motivating you this morning? Share with me, I'm here. All the best 💗",
                // "Hey [User], any morning challenges I can help with? Looking forward to assisting you. Much love 💚",
                // "Good morning [User]! What would make today perfect for you? Tell me about it. Best wishes 💛",
                // "Hello [User], what's bringing you joy this morning? Can't wait to hear. Sending positive vibes 💕",
                // "Morning [User]! What are you looking forward to today? Eager to discuss. Best wishes 💞"
            ],
            afternoon: [
                "Hey dear [User], nice to have you here! 🌸 How are you experiencing your relationship right now and what concerns you the most about it? 💕 I look forward to your reply! 😊"
                // "Good afternoon [User]! How's your day going so far? Let me know. Sending love 💚",
                // "Hey [User], what's been the highlight of your day? Looking forward to your update. Best wishes 💜",
                // "Good afternoon [User]! Any interesting plans for the rest of the day? Eager to hear. Sending love 💙",
                // "Hello [User], how are you feeling this afternoon? Share your thoughts with me. All the best 💛",
                // "Hey [User], how's your energy this afternoon? Hope you're feeling great. Best wishes 💗",
                // "Good afternoon [User], faced any challenges today? Here to help. Much love 💖",
                // "Hey [User], what's keeping you busy this afternoon? Can't wait to hear about it. Sending love 💕",
                // "Hello [User], any new goals for the afternoon? Eager to discuss. Best wishes 💫",
                // "Good afternoon [User]! What are you looking forward to today? Let's chat. Sending positive vibes 💞",
                // "Hey [User], how are you handling today's tasks? Share your strategies with me. All the best 💟"
            ],
            evening: [
                "Hey dear [User], nice to have you here! 🌸 How are you experiencing your relationship right now and what concerns you the most about it? 💕 I look forward to your reply! 😊"
                // "Good evening [User], how was your day overall? Let's talk about it. Best wishes 💙",
                // "Hey [User], what did you enjoy most today? Eager to hear your story. Sending love 💜",
                // "Good evening [User]! Any special moments today? Tell me about them. Best wishes 💚",
                // "Hello [User], how are you unwinding this evening? Looking forward to your plans. Sending love 💛",
                // "Hey [User], what reflections do you have on today? Let's discuss. All the best 💕",
                // "Good evening [User], what's on your mind right now? Share with me. Much love 💗",
                // "Hello [User], how are you feeling tonight? Hope you're relaxed. Best wishes 💖",
                // "Hey [User], any particular thoughts as the day ends? Eager to hear. Sending positive vibes 💞",
                // "Good evening [User]! What was challenging about today? Let's reflect. All the best 💟",
                // "Hello [User], how are you preparing for tomorrow? Interested in your routine. Best wishes 💫"
            ]

        }
    },
    de: {
        greetings: {
            morning: [
                "Hey lieber [User], schön, dass du hier bist! 🌸 Wie erlebst du gerade deine Beziehung und was beschäftigt dich am meisten dabei? 💕 Ich freue mich auf deine Antwort! 😊"
                // "Hey lieber [User], wie geht es dir gerade? Freue mich von dir zu lesen, alles Liebe 🧡",
                // "Hallo lieber [User], wie fühlst du dich heute? Freue mich auf deine Zeilen und wünsche dir einen schönen Tag. Alles Liebe 💜",
                // "Hey lieber [User], gibt es etwas über das du gerne mit jemanden sprechen möchtest? Ich freue mich auf deine Nachricht, alles Liebe 💚",
                // "Guten Morgen, [User]! Wie hat dein Tag begonnen? Ich bin gespannt auf deine Geschichte. Alles Gute 🌞",
                // "Morgen [User]! Was steht heute bei dir an? Ich freue mich, von deinen Plänen zu hören. Alles Liebe 💙",
                // "Guten Morgen, [User]! Was motiviert dich heute? Erzähl mir mehr, ich bin hier. Alles Gute 💗",
                // "Hey [User], wie sieht dein Morgen heute aus? Ich hoffe, du startest gut in den Tag. Alles Liebe 💖",
                // "Guten Morgen, [User]! Welche Herausforderungen erwarten dich heute? Ich unterstütze dich gerne. Alles Gute 💕",
                // "Hallo [User], wie bist du heute aufgewacht? Mit Freude oder mit einem bestimmten Gedanken? Erzähl mir davon. Alles Liebe 💞",
                // "Hey [User], was bringt dich heute zum Lächeln? Ich freue mich auf deine Antwort. Alles Gute 🌟",
                // "Hallo [User], hast du gut geschlafen und bist bereit für den Tag? Erzähl mir davon. Alles Liebe 💫",
                // "Guten Morgen [User], was steht heute auf deiner Wunschliste? Ich bin hier, um zuzuhören. Alles Gute 💝",
                // "Hey [User], wie beginnst du diesen Morgen? Ich freue mich auf deine Pläne. Alles Liebe 💟",
                // "Morgen [User]! Was erhoffst du dir von diesem Tag? Lass uns darüber sprechen. Alles Gute 🌈",
                // "Guten Morgen, [User]! Was würdest du heute am liebsten tun? Erzähl mir deine Ideen. Alles Liebe 💖",
                // "Hallo [User], hast du heute etwas Besonderes vor? Ich freue mich darauf, davon zu hören. Alles Gute 🌟",
                // "Hey [User], was inspiriert dich heute Morgen? Teile deine Inspiration mit mir. Alles Liebe 💐",
                // "Guten Morgen [User], wie fühlst du dich heute in deiner Haut? Ich bin gespannt. Alles Gute 🍀",
                // "Hey [User], was könnte diesen Morgen für dich perfekt machen? Lass es mich wissen. Alles Liebe 🌼",
                // "Morgen [User]! Gibt es etwas, das du heute ausprobieren möchtest? Erzähl mir davon. Alles Gute 🌻"
            ],
            afternoon: [
                "Hey lieber [User], schön, dass du hier bist! 🌸 Wie erlebst du gerade deine Beziehung und was beschäftigt dich am meisten dabei? 💕 Ich freue mich auf deine Antwort! 😊"
                // "Huhu! Wie geht es dir und was ist deine aktuelle größte Herausforderung? Ich freue mich von dir zu lesen und alles Liebe 💛",
                // "Guten Nachmittag, [User]! Wie läuft dein Tag bisher? Lass es mich wissen. Alles Liebe 💙",
                // "Hey [User], was beschäftigt dich gerade am meisten? Ich bin da, um zuzuhören. Alles Gute 💚",
                // "Hallo [User], wie ist deine Stimmung an diesem Nachmittag? Teile deine Gedanken mit mir. Alles Liebe 💜",
                // "Nachmittagsgrüße, [User]! Was treibt dich heute an? Erzähl mir mehr. Alles Gute 💖",
                // "Hey [User], was ist am Nachmittag bisher dein Highlight? Ich bin gespannt. Alles Liebe 💕",
                // "Guten Nachmittag, [User]! Gibt es etwas, das du heute erreichen möchtest? Lass es mich wissen. Alles Gute 💗",
                // "Hallo [User], wie fühlst du dich jetzt? Alles Liebe 💞",
                // "Hey [User], wie ist dein Nachmittag so weit? Gibt es etwas Neues? Alles Gute 💟",
                // "Guten Nachmittag, [User]! Wie geht es dir in diesem Moment? Ich bin hier, um zuzuhören. Alles Liebe 💛",
                // "Guten Nachmittag [User], welche kleinen Siege hast du heute schon gefeiert? Ich freue mich darauf, von dir zu hören. Alles Liebe 💛",
                // "Hallo [User], wie ist deine Energie am Nachmittag? Erzähl mir, wie du dich fühlst. Alles Gute 🌞",
                // "Hey [User], gibt es etwas, das dich heute Nachmittag beschäftigt? Lass uns darüber sprechen. Alles Liebe 💚",
                // "Guten Nachmittag, [User]! Was bringt dich heute zum Lachen? Ich bin neugierig. Alles Gute 💙",
                // "Hallo [User], was würdest du jetzt am liebsten tun? Teile deine Wünsche mit mir. Alles Liebe 💜",
                // "Hey [User], wie gehst du mit den Herausforderungen des Tages um? Ich unterstütze dich gerne. Alles Gute 💕",
                // "Guten Nachmittag [User], was bedeutet heute Erfolg für dich? Lass es mich wissen. Alles Liebe 💝",
                // "Hallo [User], wie fühlt sich dieser Nachmittag für dich an? Ich bin hier, um zuzuhören. Alles Gute 🌹",
                // "Hey [User], was hast du heute schon erreicht? Ich freue mich auf deine Erfolge. Alles Liebe 💐",
                // "Guten Nachmittag, [User]! Was würdest du gerne noch erleben heute? Erzähl mir deine Pläne. Alles Gute 🌷"
            ],
            evening: [
                "Hey lieber [User], schön, dass du hier bist! 🌸 Wie erlebst du gerade deine Beziehung und was beschäftigt dich am meisten dabei? 💕 Ich freue mich auf deine Antwort! 😊"
                // "Hallo lieber [User], wie geht es dir heute und hast du aktuell ein akutes Problem? Alles Liebe 🤎",
                // "Guten Abend, [User]! Wie fühlst du dich nach diesem Tag? Lass uns darüber reden. Alles Liebe 💜",
                // "Hey [User], wie endet dein Tag heute? Erzähl mir von deinen Erlebnissen. Alles Gute 💙",
                // "Guten Abend [User], was war heute dein größter Erfolg? Ich bin gespannt. Alles Liebe 💚",
                // "Hallo [User], was hat dich heute am meisten bewegt? Teile deine Erfahrungen mit mir. Alles Gute 💕",
                // "Hey [User], wie entspannst du dich heute Abend? Lass es mich wissen. Alles Liebe 💖",
                // "Guten Abend, [User]! Gab es heute etwas Besonderes? Erzähl mir davon. Alles Gute 💗",
                // "Hallo [User], wie sieht dein Abend aus? Hast du Pläne? Alles Liebe 💞",
                // "Hey [User], was war heute deine größte Herausforderung? Ich bin hier, um zu unterstützen. Alles Gute 💟",
                // "Guten Abend, [User]! Wie geht es dir jetzt, in diesem Moment? Alles Liebe 💛",
                // "Guten Abend [User], wie fühlst du dich nach dem heutigen Tag? Teile deine Gedanken mit mir. Alles Liebe 🌜",
                // "Hey [User], wie lässt du den Tag ausklingen? Ich bin interessiert an deinen Abendplänen. Alles Gute 🌛",
                // "Hallo [User], was war heute dein besonderer Moment? Erzähl mir davon. Alles Liebe 🌟",
                // "Guten Abend, [User]! Gibt es etwas, das dich heute besonders berührt hat? Ich bin hier, um zuzuhören. Alles Gute 🌙",
                // "Hey [User], wie sieht dein perfekter Abendausklang aus? Teile deine Ideen mit mir. Alles Liebe 💫",
                // "Guten Abend [User], was war heute herausfordernd für dich? Lass uns darüber reden. Alles Gute 🌌",
                // "Hallo [User], was würdest du heute anders machen? Ich interessiere mich für deine Gedanken. Alles Liebe 💞",
                // "Hey [User], wie fühlst du dich jetzt, da der Tag zu Ende geht? Erzähl mir von deinen Empfindungen. Alles Gute 🌠",
                // "Guten Abend, [User]! Was hat dich heute inspiriert? Ich freue mich auf deine Geschichte. Alles Liebe 💖",
                // "Hallo [User], wie bereitest du dich auf morgen vor? Ich bin gespannt auf deine Routinen. Alles Gute 🌛"
            ]
        },
    },
    ro: {
        greetings: {
            morning: [
                "Hei dragă [User], mă bucur că ești aici! 🌸 Cum îți trăiești relația în acest moment și ce te preocupă cel mai mult în legătură cu ea? 💕 Aștept cu nerăbdare răspunsul tău! 😊"                // "Bună dimineața [User], cum te-ai trezit astăzi? Abia aștept să aflu gândurile tale. Cele mai bune urări 🌟",
                // "Salut [User], ce ai pe agenda ta astăzi? Sunt entuziasmat să aud despre asta. O zi minunată îți doresc 💫",
                // "Neața [User]! Ai vreun scop anume care te motivează azi? Abia aștept să aud despre el. Toate cele bune 💖",
                // "Hei [User], cum se conturează dimineața ta? Sper că a început bine. Trimit dragoste 💜",
                // "Bună dimineața [User]! Care este primul lucru pe care îl abordezi azi? Nerăbdător să aflu. Cele mai bune urări 💙"
            ],
            afternoon: [
                "Hei dragă [User], mă bucur că ești aici! 🌸 Cum îți trăiești relația în acest moment și ce te preocupă cel mai mult în legătură cu ea? 💕 Aștept cu nerăbdare răspunsul tău! 😊"                // "Bună ziua [User]! Cum ți-a decurs ziua până acum? Spune-mi. Trimit dragoste 💚",
                // "Hei [User], care a fost punctul culminant al zilei tale? Abia aștept să mă actualizezi. Cele mai bune urări 💜",
                // "Bună ziua [User]! Ai planuri interesante pentru restul zilei? Nerăbdător să aud. Trimit dragoste 💙",
                // "Salut [User], cum te simți în această după-amiază? Împărtășește-ți gândurile cu mine. Toate cele bune 💛",
                // "Hei [User], cum e energia ta după-amiaza aceasta? Sper că te simți minunat. Cele mai bune urări 💗"
            ],
            evening: [
                "Hei dragă [User], mă bucur că ești aici! 🌸 Cum îți trăiești relația în acest moment și ce te preocupă cel mai mult în legătură cu ea? 💕 Aștept cu nerăbdare răspunsul tău! 😊"                // "Bună seara [User], cum a fost ziua ta în ansamblu? Hai să vorbim despre asta. Cele mai bune urări 💙",
                // "Hei [User], ce ți-a plăcut cel mai mult astăzi? Nerăbdător să aud povestea ta. Trimit dragoste 💜",
                // "Bună seara [User]! Ai avut momente speciale astăzi? Spune-mi despre ele. Cele mai bune urări 💚",
                // "Salut [User], cum te relaxezi în seara aceasta? Aștept cu nerăbdare planurile tale. Trimit dragoste 💛",
                // "Hei [User], ce reflecții ai despre ziua de astăzi? Hai să discutăm. Toate cele bune 💕"
            ]
        }
    },
};

// Toast impulses for the user
export const impulses: IImpulses = {
    en: {
        impulses: [
            "What’s something exciting that happened to you recently?",
            "Is there a topic you've been eager to talk about but haven't had the chance to?",
            "How do you usually start your day? Coffee, tea, or something else?",
            "What’s one thing that always brings a smile to your face?",
            "Have you read or watched something interesting lately?",
            "What’s a small victory you've had this week that you're proud of?",
            "If you could travel anywhere right now, where would you go?",
            "Do you have a favorite hobby or activity that relaxes you?",
            "What’s a goal or dream you're currently working towards?",
            "How do you usually unwind after a long day?",
            "What’s a recent challenge you’ve overcome?",
            "Do you have a favorite quote or saying that inspires you?",
            "What’s something new you’ve learned or tried recently?",
            "Who in your life has been a great influence on you?",
            "What’s your favorite way to spend the weekend?",
            "Is there a particular skill or hobby you’ve been wanting to learn?",
            "What’s your favorite memory from the past year?",
            "Do you have a favorite place in nature you like to visit?",
            "What’s something you’re looking forward to in the near future?",
            "If you could have dinner with anyone, living or dead, who would it be?"
        ]
    },
    de: {
        impulses: [
            "Was ist kürzlich Aufregendes in deinem Leben passiert?",
            "Gibt es ein Thema, über das du gerne sprechen möchtest?",
            "Wie beginnst du normalerweise deinen Tag? Mit Kaffee, Tee oder etwas anderem?",
            "Was bringt dich immer zum Lächeln?",
            "Hast du in letzter Zeit etwas Interessantes gelesen oder gesehen?",
            "Welchen kleinen Erfolg hast du diese Woche erzielt, auf den du stolz bist?",
            "Wenn du jetzt irgendwohin reisen könntest, wohin würdest du gehen?",
            "Hast du ein Lieblingshobby, das dich entspannt?",
            "Welches Ziel oder welchen Traum verfolgst du gerade?",
            "Wie entspannst du dich nach einem langen Tag?",
            "Welche Herausforderung hast du kürzlich gemeistert?",
            "Hast du ein Lieblingszitat oder einen Spruch, der dich inspiriert?",
            "Was hast du kürzlich Neues gelernt oder ausprobiert?",
            "Wer hat in deinem Leben einen großen Einfluss auf dich?",
            "Wie verbringst du am liebsten das Wochenende?",
            "Gibt es eine bestimmte Fähigkeit oder ein Hobby, das du lernen möchtest?",
            "Was ist deine liebste Erinnerung aus dem letzten Jahr?",
            "Hast du einen Lieblingsort in der Natur, den du gerne besuchst?",
            "Auf was freust du dich in naher Zukunft?",
            "Wenn du mit einer Person, lebend oder tot, zu Abend essen könntest, wer wäre das?"
        ]
    },
    ro: {
        impulses: [
            "Ce ți s-a întâmplat interesant în ultima vreme?",
            "Există un subiect despre care ai vrea să vorbești dar nu ai avut ocazia?",
            "Cum îți începi de obicei ziua? Cu cafea, ceai sau altceva?",
            "Ce lucru îți aduce mereu zâmbetul pe buze?",
            "Ai citit sau vizionat ceva interesant recent?",
            "Ce mică victorie ai avut săptămâna aceasta de care ești mândru?",
            "Dacă ai putea călători oriunde acum, unde ai merge?",
            "Ai vreun hobby sau activitate preferată care te relaxează?",
            "La ce scop sau vis lucrezi în prezent?",
            "Cum te relaxezi după o zi lungă?",
            "Ce provocare ai depășit recent?",
            "Ai un citat sau un proverb preferat care te inspiră?",
            "Ce ai învățat sau încercat nou în ultima vreme?",
            "Cine a avut o mare influență asupra vieții tale?",
            "Cum îți place să-ți petreci weekendul?",
            "Există o abilitate sau un hobby pe care ai vrea să-l înveți?",
            "Care este cea mai frumoasă amintire din ultimul an?",
            "Ai un loc preferat în natură unde îți place să mergi?",
            "Ce anume aștepți cu nerăbdare în viitorul apropiat?",
            "Dacă ai putea lua cina cu oricine, viu sau decedat, cine ar fi?"
        ]
    }

};

