import { IconButton, Stack, keyframes } from "@fluentui/react";
import React, { useEffect, useRef, useState } from "react";
import { MyMessageProps } from "../AppComponents/MyChat";
import { ChatMessageComponentStyleIncoming, ChatMessageComponentStyleOutgoing, ChatMessageIncoming, ChatMessageOutgoing, ChatMessagesMobileStyle, ChatMessagesStyle, ScrollIntoViewButtonStyle, Theme } from "../Utilities/MyChatStyles";
import ChatMessageComponent from "./ChatMessageComponent";
import MessageSeparator from "./MessageSeperator";
import { shouldInsertSeparator } from "../Utilities/Utility";
import useWindowSize from "../Hooks/useWindowSize";

export interface ChatMessageAreaProps {
    messages: MyMessageProps[];
    version: string;
    isMobile: boolean;
}


const ChatMessageArea: React.FC<ChatMessageAreaProps> = ({ messages, version }) => {
    // State to track window width
    const messagesContainerRef = useRef<HTMLDivElement>(null);
    const { width } = useWindowSize();
    const [buttonPressed, setButtonPressed] = useState(false);
    const isMobile = width < 1300;
    const [isScrolledToBottom, setIsScrolledToBottom] = useState(true);

    useEffect(() => {
        // Set a timeout to match the animation duration
        const timer = setTimeout(() => {
            scrollToBottom();
        }, 1000); // Delay the scroll to sync with the end of the animations
        return () => clearTimeout(timer); // Clear the timeout if the component unmounts
    }, [messages]);

    const handleButtonClick = () => {
        setButtonPressed(true);
        scrollToBottom();
    };

    const handleScroll = (event: any) => {
        const { scrollTop, scrollHeight, clientHeight } = event.currentTarget;
        const bottomPadding = 300; // Adjust this value as needed
        const isAtBottom = (scrollHeight - scrollTop - bottomPadding) <= clientHeight;
        setIsScrolledToBottom(isAtBottom);
    };

    const scrollToBottom = () => {
        if (messagesContainerRef.current) {
            const lastElement = messagesContainerRef.current!.lastElementChild;
            if (lastElement) {
                lastElement.scrollIntoView({ behavior: 'smooth' });
            }
        }
    };

    //custom fade in transition
    const fadeInIncoming = keyframes({
        '0%': { opacity: 0, transform: 'translateY(-25px)' },
        '100%': { opacity: 1, transform: 'translateY(0)' }
    });

    //custom fade out transition
    const fadeOutIncoming = keyframes({
        '0%': { opacity: 1, transform: 'translateY(0)' },
        '100%': { opacity: 0, transform: 'translateY(-25px)' }
    });

    return (
        <Stack grow styles={isMobile ? ChatMessagesMobileStyle : ChatMessagesStyle} onScroll={handleScroll}>
            <div ref={messagesContainerRef}>
                {messages.map((msg) => (
                    <React.Fragment key={msg.id}>
                        {shouldInsertSeparator(msg) && <MessageSeparator version={version} date={msg.dateSent} />}
                        <Stack styles={msg.direction === "incoming" ? ChatMessageIncoming : ChatMessageOutgoing} horizontalAlign={msg.direction === "incoming" ? "start" : "end"}>
                            <Stack.Item styles={msg.direction === "incoming" ? ChatMessageComponentStyleIncoming : ChatMessageComponentStyleOutgoing}>
                                <ChatMessageComponent singleMessageProps={msg} isMobile={isMobile} />
                            </Stack.Item>
                        </Stack>
                    </React.Fragment>
                ))}
            </div>
            {isScrolledToBottom || (
                <div style={{
                    position: 'fixed',
                    animationName: buttonPressed ? fadeOutIncoming : fadeInIncoming,
                    animationDuration: ".5s",
                    animationFillMode: 'forwards',
                    bottom: '180px',
                    background: `${Theme.palette.neutralLighter}`,
                    boxShadow: '0 4px 11px 0 rgba(0, 0, 0, 0.6)',
                    borderRadius: 32,
                    right: isMobile ? "25px" : width / 2,
                    zIndex: 100
                }}>
                    <IconButton
                        iconProps={{ iconName: 'Download' }}
                        styles={ScrollIntoViewButtonStyle}
                        onClick={handleButtonClick}
                        title="Scroll to bottom"
                    />
                </div>
            )}
        </Stack>
    );
};

export default ChatMessageArea;

