import { Stack, TextField, IconButton, ITextField } from "@fluentui/react";
import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { ChatInputAreaIconButtonStyle, ChatInputAreaStyle, TextAreaStyle } from "../Utilities/MyChatStyles";
import { languages } from "../Utilities/Localizations";
import useLocalStorage from "../Hooks/useLocalStorage";
import { toast } from "react-toastify";

export interface ChatInputAreaProps {
    setModalOpen: Dispatch<SetStateAction<boolean>>;
    setModalContent: Dispatch<SetStateAction<{ header: string; text: string; }>>;
    onSendMessage: (message: string) => void;
    inputDisabled: boolean;
    coachIsTyping: boolean;
    limitReached: boolean;
    nextAvailableTime: Date | null;
    messageCap: number;
}

const ChatInputArea: React.FC<ChatInputAreaProps> = ({ onSendMessage, inputDisabled, coachIsTyping, limitReached, messageCap, nextAvailableTime, setModalOpen, setModalContent }) => {
    const [message, setMessage] = useState('');
    const [language] = useLocalStorage('language', 'en-US'); // Default to 'en-US' if not set
    const translations = languages[language];

    const [isToastVisible, setIsToastVisible] = useState(false);
    const [rejectMessage, setRejectMessage] = useState(false);
    const messageInputRef = useRef<ITextField>(null); // Use ITextField for the ref type

    useEffect(() => {
        if (message.length > 1000 && !isToastVisible) {
            setRejectMessage(true);
            toast.error("Your message is too long. Please keep it under 1000 characters.", {
                position: "top-center", // Position the toast at the top center
                autoClose: false, // Toast will remain open until manually closed or condition changes
                onClose: () => setIsToastVisible(false),
                onOpen: () => setIsToastVisible(true)
            });
        } else if (message.length <= 1000 && isToastVisible) {
            setRejectMessage(false);
            setIsToastVisible(false);
            toast.dismiss(); // Dismiss all active toasts
        }
    }, [message, isToastVisible]);




    const handleSendMessage = () => {
        if (rejectMessage) {
            return;
        }
        if (message.trim()) {
            onSendMessage(message);
            setMessage(''); // Clears the message after sending
    
            // Refocuses on the text field and then blur to close the keyboard on mobile devices
            const inputElement = messageInputRef.current;
            if (inputElement) {
                inputElement.focus(); // Stellt sicher, dass das Eingabefeld den Fokus hat
                setTimeout(() => inputElement.blur(), 0); // Entfernt den Fokus, um die Tastatur zu schließen
            }
        }
    };
    

    const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            if (coachIsTyping || inputDisabled || rejectMessage) { return; }
            event.preventDefault(); // Prevents the default Enter key behavior
            handleSendMessage();
        }
    };

    return (
        <Stack styles={ChatInputAreaStyle}>
            <Stack horizontal horizontalAlign="stretch" verticalAlign="center">
                <Stack.Item grow>
                    <TextField
                        resizable={false}
                        componentRef={messageInputRef} // Assign the ref to the TextField
                        borderless
                        styles={TextAreaStyle}
                        placeholder={translations.messagePlaceholder}
                        value={message}
                        onChange={(e, newValue) => setMessage(newValue || '')}
                        onKeyPress={handleKeyPress}
                        autoComplete="off"
                        multiline
                        autoAdjustHeight
                    />
                </Stack.Item>
                <Stack.Item>
                    {!limitReached ? (
                        <IconButton
                            styles={ChatInputAreaIconButtonStyle}
                            disabled={coachIsTyping || inputDisabled || rejectMessage}
                            iconProps={{ iconName: "Send" }}
                            onClick={handleSendMessage}
                        />
                    ) : (
                        <IconButton
                            styles={ChatInputAreaIconButtonStyle}
                            iconProps={{ iconName: "Info" }}
                            onClick={() => {
                                setModalContent({
                                    header: translations.messageLimitHeader,
                                    text: `${translations.messageLimitText1}${messageCap}${translations.messageLimitText2}${nextAvailableTime ? nextAvailableTime.toLocaleTimeString().replace(/:\d{2}\s/, ' ') : "later"}${translations.messageLimitText3}`,
                                });
                                setModalOpen(true);
                            }}
                        />
                    )}
                </Stack.Item>
            </Stack>
        </Stack>
    );
}

export default ChatInputArea;
