import React, { useCallback, useEffect, useMemo } from 'react';
import { Stack, initializeIcons } from '@fluentui/react';
import StartScreen from './AppComponents/StartScreen';
import { AppStyleMobile, AppStyle } from './Utilities/MyChatStyles';
import { useLocalStorage } from './Hooks/useLocalStorage'; // Assuming you have a custom hook for local storage
import { mobileCheck, setViewportHeight } from './Utilities/Utility';
import { ChatContextProps, ChatContext } from './Utilities/ChatContext';
import { ToastContainer } from 'react-toastify';

initializeIcons();

function App() {
  const version = 'v2.0.0';
  const isMobile = mobileCheck(); 

  // Custom hook for managing local storage state
  const [chatState, setChatState] = useLocalStorage('chatState', {
    messages: [],
    apiMessages: [],
    username: '',
    email: '',
  } as ChatContextProps);

  const [language, setLanguage] = useLocalStorage('language', '');

  // Update the language if it's not set
  useEffect(() => {
    if (!language) {
      const browserLanguage = navigator.language || 'en-US'; // Default to 'en-US' if navigator.language is not available
      setLanguage(browserLanguage);
    } else {
      setLanguage(language);
    }
  }, //eslint-disable-next-line
    []); // Empty dependency array to ensure this runs only once on mount

// Handle viewport height adjustments
  useEffect(() => {
    const handleResize = () => {
      setViewportHeight();
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Set initial viewport height

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Update chat state
  const updateChatState = useCallback((newState: ChatContextProps) => {
    setChatState((prevState: any) => ({ ...prevState, ...newState }));
  }, [setChatState]);


  // useMemo to memoize the context value
  const contextValue = useMemo(() => ({
    state: chatState,
    updateState: updateChatState
  }), [chatState, updateChatState]);

  return (
    <ChatContext.Provider value={contextValue}>
      <Stack horizontalAlign='center' horizontal styles={isMobile ? AppStyleMobile : AppStyle}>
        <ToastContainer limit={1}/>
        <StartScreen version={version} isMobile={isMobile} />
      </Stack>
    </ChatContext.Provider>
  );
}

export default App;
