import React from "react";
import { MyMessageProps } from "../AppComponents/MyChat";

export interface ChatContextProps {
    messages: MyMessageProps[];
    apiMessages: MyMessageProps[];
}

export const ChatContext = React.createContext({
    // Initial context state
    state: {} as ChatContextProps,
    // Update function (to be implemented)
    updateState: (newState: ChatContextProps) => { }
});