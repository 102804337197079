import { Icon, IconButton, Stack } from "@fluentui/react";
import React, { useContext, useEffect, useState } from "react";
import { ChatMessageComponentIconButtonStyle } from "../Utilities/MyChatStyles";
import { MyMessageProps } from "../AppComponents/MyChat";
import { ChatContext } from "../Utilities/ChatContext";
import useLocalStorage from "../Hooks/useLocalStorage";

export enum Rating {
  Positive = 'Positive',
  Negative = 'Negative',
  None = 'None',
}

interface FeedbackActionsProps {
  targetMessage: MyMessageProps;
}

const FeedbackActions: React.FC<FeedbackActionsProps> = ({ targetMessage }) => {
  const chatContext = useContext(ChatContext); // Get messages from ChatContext
  const [isHoveringLike, setLikeHovering] = useState(false);
  const [isHoveringDislike, setDislikeHovering] = useState(false);
  const [liked, setLiked] = useState(chatContext.state.messages.find(msg => msg.id === targetMessage.id)?.rating === Rating.Positive);
  const [disliked, setDisliked] = useState(chatContext.state.messages.find(msg => msg.id === targetMessage.id)?.rating === Rating.Negative);
  const [feedbackGiven, setFeedbackGiven] = useState(false); // New state variable
  const [username] = useLocalStorage('username', '');

  useEffect(() => {
    setLiked(chatContext.state.messages.find(msg => msg.id === targetMessage.id)?.rating === Rating.Positive);
    setDisliked(chatContext.state.messages.find(msg => msg.id === targetMessage.id)?.rating === Rating.Negative);
  }, [chatContext, targetMessage]);


  const handleReaction = async (rating: Rating) => {
    // Find the index of the target message
    const targetIndex = chatContext.state.messages.findIndex(msg => msg.id === targetMessage.id);

    // If the target message is not found, return
    if (targetIndex === -1) {
      return;
    }

    // Determine the number of messages to store based on the rating
    const numMessages = 5;

    // Get the last numMessages messages before the target message and the target message itself
    const context = chatContext.state.messages.slice(Math.max(0, targetIndex - numMessages), targetIndex + 1);

    // Prepare the reaction data
    const reactionData = {
      Rating: rating,
      Context: context,
      Username: username, // Add the username
    };

    // Update the messages in the context with the new rating
    const newMessages = [...chatContext.state.messages];
    for (let i = Math.max(0, targetIndex - numMessages + 1); i <= targetIndex; i++) {
      if (newMessages[i].rating === Rating.None) {
        newMessages[i] = {
          ...newMessages[i],
          rating: rating,
        };
      }
    }
    chatContext.updateState({
      ...chatContext.state,
      messages: newMessages,
    });
    localStorage.setItem('chatState', JSON.stringify({ ...chatContext.state, messages: newMessages }));

    // Call the Azure Function
    const response = await fetch('/api/StoreChatReaction', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(reactionData),
    });

    if (!response.ok) {
      // Handle the error
      console.error('Failed to store reaction');
    }
  };

  const handleLikeClick = () => {
    setLiked(!liked);
    if (disliked) {
      setDisliked(false);
    }
    handleReaction(Rating.Positive); // Call handleReaction function
    setFeedbackGiven(true); // Set feedbackGiven to true
  };

  const handleDislikeClick = () => {
    setDisliked(!disliked);
    if (liked) {
      setLiked(false);
    }
    handleReaction(Rating.Negative); // Call handleReaction function
    setFeedbackGiven(true); // Set feedbackGiven to true
  };

  return (
    <>
      <Stack horizontal  tokens={{ childrenGap: 12 }} horizontalAlign="start">
        {!feedbackGiven && liked && (
          <Stack.Item style={{paddingBottom: 20}}>
            <Icon
              styles={ChatMessageComponentIconButtonStyle}
              iconName={"DoubleChevronUp12"}
              style={{ color: 'green', margin: "0px -16px -8px 0px" }}
            />
          </Stack.Item>
        )}
        {!feedbackGiven && disliked && (
          <Stack.Item style={{paddingBottom: 20}}>
            <Icon
              styles={ChatMessageComponentIconButtonStyle}
              iconName={"DoubleChevronDown12"}
              style={{ color: 'red', margin: "0px -16px -8px 0px" }}
            />
          </Stack.Item>
        )}
        {!feedbackGiven && !liked && !disliked && (
          <>
            <Stack.Item style={{paddingBottom: 20}}>
              <IconButton
                styles={ChatMessageComponentIconButtonStyle}
                iconProps={{
                  iconName: isHoveringLike || liked ? "LikeSolid" : "Like",
                  styles: { 
                    root: { 
                      color: isHoveringLike ? 'green' : (liked ? 'green' : undefined),
                    }
                  },
                }}
                onMouseEnter={() => setLikeHovering(true)}
                onMouseLeave={() => setLikeHovering(false)}
                onClick={handleLikeClick}
                disabled={feedbackGiven}
              />
            </Stack.Item>
            <Stack.Item style={{paddingBottom: 20}}>
              <IconButton
                styles={ChatMessageComponentIconButtonStyle}
                iconProps={{
                  iconName: isHoveringDislike || disliked ? "DislikeSolid" : "Dislike",
                  styles: { 
                  root: { 
                    color: isHoveringDislike ? 'red' : (disliked ? 'red' : undefined) 
                  } 
                },
                }}
                onMouseEnter={() => setDislikeHovering(true)}
                onMouseLeave={() => setDislikeHovering(false)}
                onClick={handleDislikeClick}
                disabled={feedbackGiven}
              />
            </Stack.Item>
          </>
        )}
        {feedbackGiven && (
          <>
            {!disliked && (
              <Stack.Item style={{paddingBottom: 20, marginTop: "-6px", marginRight: "-8px"}}>
                <IconButton
                  styles={ChatMessageComponentIconButtonStyle}
                  iconProps={{
                    iconName: isHoveringLike || liked ? "LikeSolid" : "Like",
                    styles: { root: { color: liked ? 'green' : undefined } },
                  }}
                  onMouseEnter={() => setLikeHovering(true)}
                  onMouseLeave={() => setLikeHovering(false)}
                  onClick={handleLikeClick}
                  disabled={feedbackGiven}
                />
              </Stack.Item>
            )}
            {!liked && (
              <Stack.Item style={{paddingBottom: 20, marginRight: "-8px"}}>
                <IconButton
                  styles={ChatMessageComponentIconButtonStyle}
                  iconProps={{
                    iconName: isHoveringDislike || disliked ? "DislikeSolid" : "Dislike",
                    styles: { root: { color: disliked ? 'red' : undefined } },
                  }}
                  onMouseEnter={() => setDislikeHovering(true)}
                  onMouseLeave={() => setDislikeHovering(false)}
                  onClick={handleDislikeClick}
                  disabled={feedbackGiven}
                />
              </Stack.Item>
            )}
          </>
        )}
      </Stack>
    </>
  );
};

export default FeedbackActions;