import React from 'react';
import { Stack, Text } from "@fluentui/react";
import { ChatInputAreaAnimationStyle, IsTypingTextStyle } from '../Utilities/MyChatStyles';
import { languages } from '../Utilities/Localizations';
import useLocalStorage from '../Hooks/useLocalStorage';

interface TypingAnimationProps {
}

const TypingAnimation: React.FC<TypingAnimationProps> = () => {
    const [language] = useLocalStorage('language', 'en-US'); // Default to 'en-US' if not set
    const translations = languages[language];
    return (
        <Stack styles={ChatInputAreaAnimationStyle} horizontal horizontalAlign="start" verticalAlign="center">
            <Stack.Item>
                <Text
                    styles={IsTypingTextStyle}
                    variant='large'
                >
                    {translations.isTyping}
                </Text>
            </Stack.Item>
        </Stack>
    );
};

export default TypingAnimation;
