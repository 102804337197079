//ignore all warnings in this file
/* eslint-disable */

import { DeepPartial } from "@fluentui/merge-styles";
import { IButtonStyles, IComboBoxStyles, ISliderStyles, IStackStyles, ITextFieldStyles, ITextStyles, ITheme, keyframes } from "@fluentui/react";

export const Theme: ITheme = {
    palette: {
        //primary colors
        themePrimary: 'rgba(54,137,179,1)',
        neutralPrimaryAlt: 'rgba(54,137,179, .4)',

        themeSecondary: 'rgba(124,124,124,255)',
        neutralLighter: "rgba(239,239,239,255)",


        themeTertiary: 'rgba(123,94,145,255)',
        neutralPrimary: 'rgba(123,94,145,.8)',
        neutralTertiary: 'rgba(176,196,221,255)',

        themeDark: 'rgba(57,84,110,1)',
        themeDarkAlt: 'rgba(57,84,110 .1)',

        themeLight: 'rgba(206,217,222,.65)',
        themeLighter: 'rgba(206,217,222, .4)',
        themeLighterAlt: 'rgba(206,217,222, .2)',

        neutralDark: '#000000',
        neutralLight: '#000000',

        //secondary colors
        themeDarker: '#000000',

        neutralLighterAlt: 'rgba(230, 220, 233, .65)',
        neutralQuaternary: '#000000',
        neutralQuaternaryAlt: '#000000',
        neutralTertiaryAlt: '#000000',
        neutralSecondary: '#000000',
        black: 'rgba(57,84,110,1)',
        white: 'rgba(255,255,255,1)',
        blackTranslucent40: "",
        neutralSecondaryAlt: "",
        accent: "",
        whiteTranslucent40: "",
        yellowDark: "",
        yellow: "",
        yellowLight: "",
        orange: "",
        orangeLight: "",
        orangeLighter: "",
        redDark: "",
        red: "",
        magentaDark: "",
        magenta: "",
        magentaLight: "",
        purpleDark: "",
        purple: "",
        purpleLight: "",
        blueDark: "",
        blueMid: "",
        blue: "",
        blueLight: "",
        tealDark: "",
        teal: "",
        tealLight: "",
        greenDark: "",
        green: "",
        greenLight: ""
    },
    fonts: {
        tiny: {
            fontFamily: "roboto, sans-serif",
            fontSize: "10px",
            fontWeight: 400,
            lineHeight: 14,
        },
        xSmall: {
            fontFamily: "roboto, sans-serif",
            fontSize: "10px",
            fontWeight: 400,
            lineHeight: 14,
        },
        small: {
            fontFamily: "roboto, sans-serif",
            fontSize: "12px",
            fontWeight: 400,
            lineHeight: 16,
        },
        smallPlus: {
            fontFamily: "roboto, sans-serif",
            fontSize: "12px",
            fontWeight: 400,
            lineHeight: 16,
        },
        medium: {
            fontFamily: "roboto, sans-serif",
            fontSize: "14px",
            fontWeight: 400,
            lineHeight: 20,
        },
        mediumPlus: {
            fontFamily: "roboto, sans-serif",
            fontSize: "16px",
            fontWeight: 400,
            lineHeight: 22,
        },
        large: {
            fontFamily: "roboto, sans-serif",
            fontSize: "18px",
            fontWeight: 400,
            lineHeight: 24,
        },
        xLarge: {
            fontFamily: "roboto, sans-serif",
            fontSize: "20px",
            fontWeight: 400,
            lineHeight: 28,
        },
        xxLarge: {
            fontFamily: "roboto, sans-serif",
            fontSize: "28px",
            fontWeight: 400,
            lineHeight: 34,
        },
        mega: {
            fontFamily: "roboto, sans-serif",
            fontSize: "72px",
            fontWeight: 400,
            lineHeight: 72,
        },
        xLargePlus: {
            fontFamily: "roboto, sans-serif",
            fontSize: "24px",
            fontWeight: 400,
            lineHeight: 32,
        },
        xxLargePlus: {
            fontFamily: "roboto, sans-serif",
            fontSize: "32px",
            fontWeight: 400,
            lineHeight: 40,
        },
        superLarge: {
            fontFamily: "roboto, sans-serif",
            fontSize: "42px",
            fontWeight: 400,
            lineHeight: 54,
        },
    },
    semanticColors: undefined!,
    isInverted: false,
    disableGlobalClassNames: false,
    spacing: undefined!,
    effects: undefined!
};

export const fadeInIncoming = keyframes({
    '0%': { opacity: 0, transform: 'translateX(130px)' },
    '100%': { opacity: 1, transform: 'translateY(0)' }
});

export const fadeInOutgoing = keyframes({
    '0%': { opacity: 0, transform: 'translateX(100px)' },
    '100%': { opacity: 1, transform: 'translateY(0)' }
});

export const fadeInNoMovement = keyframes({
    '0%': { opacity: 0 },
    '100%': { opacity: 1 }
});

//App STYLES
export const AppStyle: IStackStyles = {
    root: {
        background: `white`,
        borderRadius: '8px',
        height: '98vh',
        
        overflowY: 'hidden',
    }
};


export const AppStyleMobile: IStackStyles = {
    root: {
        background: `white`,
        borderRadius: '8px',
        
        height: '96vh',
        overflowY: 'hidden',
    }
};

//StartScreen STYLES
export const StartScreenStyle: IStackStyles = {
    root: {
        borderRadius: '8px',
        padding: '10px',
        
        background: `white`,
        width: "100%",
        height: '100%',
        overflowY: 'auto',
    }
};

export const StartScreenTextFieldStyle: any = {
    fieldGroup: {
        borderRadius: 8,
        borderColor: 'rgba(219,219,219,255)',
        backgroundColor: "rgba(250,250,250,255)",
        ':hover': { borderColor: 'rgba(219,219,219,255)' },
        ':active': { borderColor: 'rgba(219,219,219,255)' },
        ':focus': { borderColor: 'rgba(219,219,219,255)' },
    },
    field: {
        fontFamily: Theme.fonts.xLarge.fontFamily
    }
}

//Other STYLES

export const ModalStyle: IStackStyles = {
    root: {
        margin: '0 auto', // Centers the modal
        padding: '2rem', // Uniform padding
        maxWidth: '500px', // Maximum width, you can adjust this value
        width: '90%', // Modal width, responsive to the screen size
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.2)', // Subtle shadow for depth
        borderRadius: '0.8rem', // Slightly rounded corners
        backgroundColor: 'white', // Background color for the modal content
        display: 'flex', // Ensures flexbox layout
        flexDirection: 'column', // Stacks children vertically
        fontFamily: Theme.fonts.xLarge.fontFamily,
        alignItems: 'center', // Center aligns children horizontally
        justifyContent: 'center', // Center aligns children vertically
        gap: '1rem', // Spacing between children
        border: '1px solid rgba(0, 0, 0, 0.1)', // Adds a subtle border
        position: 'fixed', // Positioning relative to the viewport
        top: '50%', // Centering from the top down
        left: '50%', // Centering from the left to the right
        transform: 'translate(-50%, -50%)', // Adjusting the exact center position
        zIndex: 1000, // Ensures the modal is above other elements
    }
};

export const ModalOverlayStyle: IStackStyles = {
    root: {
        position: 'fixed', // Overlay takes the entire screen
        fontFamily: Theme.fonts.xLarge.fontFamily,
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.4)', // Semi-transparent background
        display: 'flex', // Enables flexbox for centering the modal
        justifyContent: 'center', // Horizontally center the modal in the viewport
        alignItems: 'center', // Vertically center the modal in the viewport
        zIndex: 999 // Just below the modal z-index to ensure it's on top of other content
    }
};

export const ModalContentStyle: IStackStyles = {
    root: {
        margin: '0 auto', // Centers the modal
        padding: '2rem', // Uniform padding
        maxWidth: '500px', // Maximum width, you can adjust this value
        width: '90%', // Modal width, responsive to the screen size
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', // Subtle shadow for depth
        borderRadius: '0.5rem', // Slightly rounded corners
        backgroundColor: 'white', // Background color for the modal content
        display: 'flex', // Ensures flexbox layout
        flexDirection: 'column', // Stacks children vertically
        alignItems: 'center', // Center aligns children horizontally
        justifyContent: 'center', // Center aligns children vertically
        gap: '1rem', // Spacing between children
        border: 'none', // Set the border to none to remove it
        position: 'relative', // Positioned relative within the overlay
        zIndex: 1000, // Ensures the modal is above the overlay
    }
};

export const IconButtonStyle: IButtonStyles = {
    root: { color: `${Theme.palette.black}`, borderRadius: 32, },
    rootHovered: { color: `${Theme.palette.black}`, borderRadius: 32, }
}

export const IconButtonStyleOffset: IButtonStyles = {
    root: { color: `${Theme.palette.black}`, background: `${Theme.palette.themeLight}`, fontWeight: 800, borderRadius: 32, bottom: 0 },
    rootHovered: { color: `${Theme.palette.black}`, borderRadius: 32, },
}


export const ComboBoxStyle: Partial<IComboBoxStyles> = {
    root: { maxWidth: 140, minWidth: 140, fontWeight: 600, borderRadius: 8, borderColor: `${Theme.palette.themeDarkAlt}` },
    container: { fontWeight: 600, borderRadius: 8, borderColor: `${Theme.palette.themeDarkAlt}` },
    rootHovered: { fontWeight: 600, borderRadius: 8, borderColor: `${Theme.palette.themeDarkAlt}` },
    callout: { fontWeight: 600, borderRadius: 8, borderColor: `${Theme.palette.themeDarkAlt}` },
    header: { fontWeight: 600, color: `${Theme.palette.black}`, borderColor: `${Theme.palette.themeDarkAlt}`, background: `${Theme.palette.themeLight}` },
    optionsContainerWrapper: { fontWeight: 600, borderRadius: 8, borderColor: `${Theme.palette.themeDarkAlt}` },
    optionsContainer: { fontWeight: 600, borderRadius: 8, borderColor: `${Theme.palette.themeDarkAlt}`, background: `${Theme.palette.themeLighter}` },
}

export const SliderStyle: Partial<ISliderStyles> = {
    root: { width: 145, },
    thumb: { backgroundColor: `${Theme.palette.themeLight}`, border: `1px solid ${Theme.palette.themeDarkAlt}` },
    activeSection: { backgroundColor: `${Theme.palette.themeLight}` },

    inactiveSection: { backgroundColor: `${Theme.palette.themeLighter}` }
}

export const OptionsStyle: IStackStyles = { root: { padding: 8, borderRadius: 8, border: `2px solid ${Theme.palette.themeLighter}` } }

export const PrimaryButtonStyle: IButtonStyles = {
    root: {
        color: `${Theme.palette.white}`,
        width: "100%",
        borderRadius: 8,
        border: 'none',
        background: `${Theme.palette.themeTertiary}`,
        fontFamily: Theme.fonts.xLarge.fontFamily,
        fontWeight: Theme.fonts.xLarge.fontWeight,
    },
    rootHovered: {
        color: `${Theme.palette.white}`,
        border: 'none',
        background: `${Theme.palette.neutralPrimary}`,
        fontWeight: Theme.fonts.xLarge.fontWeight,
    },
    rootPressed: {
        border: 'none',
        backgroundColor: `rgba(255,255,255,0.7))`,
        fontWeight: Theme.fonts.xLarge.fontWeight,
    },
}

//fade in animations with just opacity
const fadeIn = keyframes({
    '0%': { opacity: 0},
    '100%': { opacity: 1}
});

//Chat STYLES
export const ChatStyle: IStackStyles = {
    root: {
        width: '100%',
        overflowX: 'hidden',
        marginBottom: 10,
        overflowY: 'hidden',
        animationName: fadeIn,
        animationDuration: '3s',
        animationFillMode: 'forwards',
    }
}

// Add a wrapper style
export const ChatHeaderWrapperStyle: React.CSSProperties = {
    position: 'fixed',
    background: `white`,
    paddingTop: 25,
    top: 0,
    left: 0,
    right: 0,
    zIndex: 1000 // Ensure it's above other elements
};


//Header STYLES
export const ChatHeaderStyle: IStackStyles = {
    root: {
        borderRadius: '8px',
        borderBottom: `2px solid ${Theme.palette.themeTertiary}`,
        background: `white`,
        padding: '10px 10px 0px 10px',
        zIndex: 3, // Higher z-index so the header is on top of the messages
        height: 85,
    }
};
export const ChatHeaderButtonStyle: IButtonStyles = {
    root: { borderRadius: 32, width: 44, height: 44 },
    rootHovered: { borderRadius: 32, backgroundColor: "rgba(255, 255, 255, 0)" },
    rootDisabled: { backgroundColor: "rgba(255, 255, 255, 0)", borderRadius: 32, width: 44, height: 44 },
    rootPressed: { borderRadius: 32, backgroundColor: "rgba(255, 255, 255, 0)" },
    icon: { color: Theme.palette.themeTertiary, fontSize: 30, fontWeight: 700, paddingLeft: 2 },
    iconHovered: { color: Theme.palette.black, fontSize: 30, fontWeight: 900, paddingLeft: 2 },
    iconPressed: { color: Theme.palette.black, fontSize: 30, fontWeight: 900, paddingLeft: 2 },
}

export const ChatHeaderMsgButtonStyle: IButtonStyles = {
    root: { borderRadius: 32, width: 44, height: 44 },
    rootHovered: { color: Theme.palette.themeTertiary, borderRadius: 32, backgroundColor: "rgba(255, 255, 255, 0)" },
    rootDisabled: { color: Theme.palette.themeTertiary, backgroundColor: "rgba(255, 255, 255, 0)", borderRadius: 32, width: 44, height: 44 },
    rootPressed: { borderRadius: 32, backgroundColor: "rgba(255, 255, 255, 0)" },
    icon: { color: Theme.palette.themeTertiary, fontSize: 30, fontWeight: 700, paddingLeft: 2 },
    iconHovered: { color: Theme.palette.black, fontSize: 30, fontWeight: 900, paddingLeft: 2 },
    iconPressed: { color: Theme.palette.black, fontSize: 30, fontWeight: 900, paddingLeft: 2 },
    iconDisabled: { color: Theme.palette.themeTertiary, fontSize: 30, fontWeight: 700, paddingLeft: 2 },
}

export const AvatarButtonStyle: React.CSSProperties = {
    width: '70px', // Set the width you want for the avatar
    height: '70px', // The height should be the same as the width to maintain a circle
    marginLeft: '10px', // Add margin to the left to separate the avatar from the text
    marginTop: '-10px', // Add margin to the top to align the avatar with the text
    borderRadius: '50%', // Circular shape
    border: `2px solid ${Theme.palette.themeTertiary}`, // Add a border to the avatar
    backgroundColor: `${Theme.palette.neutralLighter}`,
    display: 'flex', // Use flex for proper alignment
    alignItems: 'center', // Center content vertically
    justifyContent: 'center', // Center content horizontally
    overflow: 'hidden', // Hide the overflow to maintain the circle shape
    position: 'relative', // Use relative for positioning the pseudo-elements
    cursor: 'pointer', // Change the cursor to a pointer when hovering over the avatar
};

export const ImageStyle: React.CSSProperties = {
    width: '100%', // Use 100% of the parent container
    height: '100%', // Use 100% of the parent container
    objectFit: 'cover', // Cover the container while maintaining aspect ratio
    objectPosition: 'center', // Center the image within the container
    transform: 'scale(1.3)', // Zoom in the image
};



export const StartScreenButtonStyle: IButtonStyles = {
    root: { borderRadius: 16, width: 32, height: 32, margin: "12px 12px 0px 0px" },
    rootHovered: { borderRadius: 16, backgroundColor: "rgba(255, 255, 255, 0)" },
    rootDisabled: { backgroundColor: "rgba(255, 255, 255, 0)", borderRadius: 16, width: 22, height: 22 },
    rootPressed: { borderRadius: 16, backgroundColor: "rgba(255, 255, 255, 0)" },
    icon: { color: Theme.palette.themeSecondary, fontSize: 22, fontWeight: 700, paddingLeft: 1 },
    iconHovered: { color: Theme.palette.black, fontSize: 22, fontWeight: 900, paddingLeft: 1 },
    iconPressed: { color: Theme.palette.black, fontSize: 22, fontWeight: 900, paddingLeft: 1 },
}

export const ScrollIntoViewButtonStyle: IButtonStyles = {
    root: { borderRadius: 32, width: 38, height: 38 },
    rootHovered: { borderRadius: 32, backgroundColor: "rgba(255, 255, 255, 0)" },
    icon: { color: Theme.palette.themeTertiary, fontSize: 24, fontWeight: 500, paddingLeft: 2 },
    iconHovered: { color: Theme.palette.neutralTertiary, fontSize: 24, fontWeight: 700, paddingLeft: 2 },
}

// ChatMessages STYLES
export const ChatMessagesStyle: IStackStyles = {
    root: {
        paddingTop: "310px", // Add padding to the top to make room for the header
        paddingRight: "50vh",
        paddingLeft: "50vh",
        paddingBottom: "135px",
        alignSelf: 'center',
        overflowX: 'hidden',
        minHeight: '100%',
        overflowY: 'auto', // Add scroll to the y-axis
        zIndex: 1, // Lower z-index so the messages go under the header
        marginTop: '-20vh', // Negative margin to pull messages under the header,
        selectors: {
            '::-webkit-scrollbar': {
                width: '10px',
            },
            '::-webkit-scrollbar-track': {
                background: '#f1f1f1',
                borderRadius: '5px', // Adjust as needed
            },
            '::-webkit-scrollbar-thumb': {
                background: '#888',
                borderRadius: '5px', // Adjust as needed
            },
            '::-webkit-scrollbar-thumb:hover': {
                background: '#555',
            },
        }
    },
}


export const ChatMessagesMobileStyle: IStackStyles = {
    root: {
        paddingTop: "280px", // Add padding to the top to make room for the header
        paddingRight: "4%",
        paddingLeft: "4%",
        paddingBottom: "0px",
        alignSelf: 'center',
        minHeight: '95%',
        overflowX: 'hidden',
        overflowY: 'auto', // Add scroll to the y-axis
        zIndex: 1, // Lower z-index so the messages go under the header
        marginTop: '-20vh', // Negative margin to pull messages under the header,
        selectors: {
            '::-webkit-scrollbar': {
                width: '10px',
            },
            '::-webkit-scrollbar-track': {
                background: '#f1f1f1',
                borderRadius: '5px', // Adjust as needed
            },
            '::-webkit-scrollbar-thumb': {
                background: '#888',
                borderRadius: '5px', // Adjust as needed
            },
            '::-webkit-scrollbar-thumb:hover': {
                background: '#555',
            },
        }
    },
}

export const ChatMessageIncoming: IStackStyles = {
    root: {
        animationName: fadeInIncoming,
        animationDuration: '1s',
        animationFillMode: 'forwards',
        horizontalAlign: 'start',
        marginLeft: '0px',
        marginTop: '0px',
        zIndex: 2,
    }
}

export const ChatMessageOutgoing: IStackStyles = {
    root: {
        animationName: fadeInOutgoing,
        animationDuration: '1s',
        animationFillMode: 'forwards',
        horizontalAlign: 'end',
        marginLeft: '80px',
        marginTop: '-34px',
        zIndex: 2,
    }
}

//ChatMessagesComponent STYLES
export const ChatMessageComponentStyleIncoming: IStackStyles = {
    root: {
        width: "auto",
        maxWidth: "80%",
        padding: "12px 16px 12px 16px",
        margin: "8px 0px 8px 0px",
        borderTopLeftRadius: 12,
        borderTopRightRadius: 12,
        borderBottomLeftRadius: 1,
        borderBottomRightRadius: 12,
        backgroundColor: `${Theme.palette.neutralLighter}`,
        boxShadow: '0 4px 11px 0 rgba(0, 0, 0, 0.4)', // Add shadow for depth
    }
}

export const ChatMessageComponentStyleOutgoing: IStackStyles = {
    root: {
        width: "auto",
        maxWidth: "100%",
        boxShadow: '0 4px 11px 0 rgba(0, 0, 0, 0.4)', // Add shadow for depth
        padding: "12px 16px 12px 16px",
        margin: "48px 0px 16px 0px",
        borderTopLeftRadius: 12,
        borderTopRightRadius: 12,
        borderBottomLeftRadius: 12,
        borderBottomRightRadius: 1,
        backgroundColor: `${Theme.palette.neutralTertiary}`,
    }
}

export const ChatMessageComponentIconButtonStyle: IButtonStyles = {
    root: { color: `${Theme.palette.themeSecondary}`, fontWeight: 400, borderRadius: 32, width: 26, height: 26 },
    rootHovered: { borderRadius: 32, backgroundColor: "rgba(255, 255, 255, 0)" },
    rootPressed: { borderRadius: 32, backgroundColor: "rgba(255, 255, 255, 0)" },
    icon: { fontSize: 20, fontWeight: 700 },
    iconHovered: { fontSize: 18, fontWeight: 900 },
}

export const ChatMessageTextComponentLongMessageStyle: any = {
    fontWeight: 500, // Normal font weight is 400, so 500 is a bit bolder
    maxWidth: "100%", // Ensure the div doesn't exceed the width of its container
    overflowWrap: "break-word", // Break long words to prevent overflow
    wordBreak: "break-all", // Use this to break apart continuous strings of text
    fontSize: Theme.fonts.xLarge.fontSize, // Set the font size
    color: "black", // Set the text color
    overflowX: 'auto', // Add scroll to the x-axis
    fontFamily: Theme.fonts.xLarge.fontFamily
    // You can add more styles as needed
};

export const ChatMessageOutgoingTextComponentStyle: any = {
    fontWeight: 300, // Slightly bolder than normal
    maxWidth: "100%", // Ensure the div doesn't exceed the width of its container
    overflowWrap: "break-word", // Break long words to prevent overflow
    fontSize: Theme.fonts.xLarge.fontSize, // Set the font size
    fontFamily: Theme.fonts.xLarge.fontFamily
    // No need for overflowX as it may introduce horizontal scrolling which is usually not desired
};

export const ChatMessageIncomingTextComponentStyle: any = {
    fontWeight: 300, // Slightly bolder than normal
    maxWidth: "100%", // Ensure the div doesn't exceed the width of its container
    overflowWrap: "break-word", // Break long words to prevent overflow
    fontSize: Theme.fonts.xLarge.fontSize, // Set the font size
    fontFamily: Theme.fonts.xLarge.fontFamily
    // No need for overflowX as it may introduce horizontal scrolling which is usually not desired
};

//ChatInputArea STYLES
export const ChatInputAreaStyle: IStackStyles = {
    root: {
        alignSelf: 'center',
        width: '100%', // Use 100% width for full responsiveness
        maxWidth: '640px', // Set a max-width to prevent the input area from being too wide on large screens
        zIndex: 2, // Higher z-index so the input area is on top of the messages
        maxHeight: 100,
        marginTop: 20
    }
};

export const ChatInputAreaAnimationStyle: IStackStyles = {
    root: {
        animationName: fadeInIncoming,
        animationDuration: '1s',
        animationFillMode: 'forwards',
    }
};

export const IsTypingTextStyle: ITextStyles = {
    root: {
        // ... other styles
        color: Theme.palette.themeSecondary,
        fontWeight: 600,
    },
};

export const HeaderTextStyle: ITextStyles = {
    root: {
        // ... other styles
        color: Theme.palette.themeSecondary,
        fontWeight: 700,
        cursor: 'pointer',
    },
};

export const IsRejectedTextStyle: ITextStyles = {
    root: {
        // ... other styles
        color: Theme.palette.themeTertiary,
        fontWeight: 600,
    },
};

export const InputHeaderStyle: IStackStyles = {
    root: {
        height: 10
    }
};

export const TextAreaStyle: ITextFieldStyles = {
    root: {
        flexGrow: 1, // Allow the TextField to grow
        borderRadius: '16px',
        height: 'auto', // Set height to auto to allow it to grow with content
        maxHeight: '120px', // Set a maximum height to restrict the height of the TextField
        padding: '0.5rem', // Use rem for padding,
        //make the overflow not scrollable
        overflowY: 'hidden',
    },
    fieldGroup: {
        borderRadius: '16px',
        backgroundColor: `${Theme.palette.neutralLighter}`,
        border: 'none',
        selectors: {
            '&:focus': {
                borderRadius: '16px',
            }
        }
    },
    field: {
        padding: '0.5rem', // Use rem for padding
        fontSize: '1rem', // Use rem for font sizes to be relative to base font size
        fontWeight: Theme.fonts.xLarge.fontWeight,
        fontFamily: Theme.fonts.xLarge.fontFamily,
        overflowY: 'auto', // Enable vertical scrolling
        maxHeight: '150px', // Set a maximum height for the TextField
        lineHeight: '1.5', // Use unitless line-height for proportional spacing,
        paddingRight: 50,
        paddingLeft: 10,
        border: 'none',
        selectors: {
            // Target the disabled state
            '&:disabled': {
                // Define your disabled styles here
                backgroundColor: 'lightgrey',
                color: 'grey',
                border: '1px solid grey',
                borderRadius: '16px',
            },
            '::-webkit-scrollbar': {
                width: '8px',
            },
            '::-webkit-scrollbar-track': {
                background: '#f1f1f1',
                borderRadius: '5px', // Adjust as needed
            },
            '::-webkit-scrollbar-thumb': {
                background: '#888',
                borderRadius: '5px', // Adjust as needed
            },
            '::-webkit-scrollbar-thumb:hover': {
                background: '#555',
            },
        },
    },
    prefix: undefined,
    suffix: undefined,
    icon: undefined,
    description: undefined,
    wrapper: undefined,
    errorMessage: undefined,
    subComponentStyles: {
        label: function (props: any): DeepPartial<any> {
            throw new Error("Function not implemented.");
        }
    },
    revealButton: undefined,
    revealSpan: undefined,
    revealIcon: undefined
};


export const ChatInputAreaIconButtonStyle: IButtonStyles = {
    root: { color: Theme.palette.themeTertiary, borderRadius: 32, width: 42, height: 42, zIndex: 3, marginLeft: "-62px" },
    icon: { color: Theme.palette.themeTertiary, fontSize: 30, fontWeight: 700, paddingLeft: 4 },
    iconHovered: { color: Theme.palette.themeTertiary, fontSize: 30, fontWeight: 900, paddingLeft: 4 },
}

//Footer STYLES
export const ChatFooterStyle: IStackStyles = {
    root: {
        height: 'auto',
    }
};

export const ChatFooterTextStyle: ITextStyles = {
    root: {
        fontSize: '.75rem',
        fontWeight: 700,
        color: 'rgba(0,0,0,0.6)',
        textAlign: 'center',
        padding: '-.5rem .5rem .5rem .5rem',
    }
};
