// MessageSeparator.tsx
import React from 'react';
import { differenceInCalendarDays } from 'date-fns';
import { Theme } from '../Utilities/MyChatStyles';
import { useLocalStorage } from '../Hooks/useLocalStorage';
import { languages } from '../Utilities/Localizations';

export interface MessageSeparatorProps {
    version: string;
    date: Date;
}
const formatDate = (date: Date, translations: any) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    let messageDate;
    try {
        messageDate = new Date(date);
        messageDate.setHours(0, 0, 0, 0);
    } catch (e) {
        console.error("Invalid date format:", e);
        return "Invalid Date";
    }

    const diff = differenceInCalendarDays(today, messageDate);

    if (isNaN(diff)) {
        return "Invalid Date";
    }

    if (diff === 0) {
        return translations.today;
    } else if (diff === 1) {
        return translations.yesterday;
    } else {
        // Ensure messageDate is a valid Date object
        return (messageDate instanceof Date && !isNaN(messageDate.valueOf())) ? messageDate.toLocaleDateString() : "Invalid Date";
    }
};
const MessageSeparator: React.FC<MessageSeparatorProps> = ({ date }) => {
    const [language] = useLocalStorage('language', 'en-US');
    const translations = languages[language];
    const formattedDate = formatDate(date, translations);

    return (
        <div style={{
            width: '100%',
            textAlign: 'center',
            margin: '30px 0px 20px 0px',
        }}>
            <span style={{
                background: Theme.palette.white,
                padding: '1px 40px 3px 40px',
                borderRadius: 8,
                fontFamily: Theme.fonts.small.fontFamily,
                fontSize: Theme.fonts.large.fontSize,
                fontWeight: 600,
                color: Theme.palette.themeSecondary,
                // Additional styles to ensure it stands out appropriately
                display: 'inline-block', // Ensures the background and padding only affect the text
                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', // Optional: adds a subtle shadow for depth
            }}>
                {formattedDate}
            </span>
        </div>
    );
};

export default MessageSeparator;
